@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}




































































time {
  @apply whitespace-no-wrap border-b border-dotted border-gray-400;
}
