@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}













































div {
  @apply font-semibold text-lg my-4 p-4 bg-gray-100 rounded;

  &.error {
    @apply bg-red-100 text-red-700;
  }

  &.success {
    @apply bg-green-100 text-green-700;
  }
}

button {
  @apply opacity-75;

  &:hover {
    @apply opacity-100;
  }
}
