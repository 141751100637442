@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}

























span {
  @apply rounded text-white bg-gray-700 font-semibold py-1 px-2 text-xs leading-none font-sans;

  &.notice {
    @apply bg-pink-200 text-pink-800;
  }

  &.question {
    @apply bg-purple-200 text-purple-800;
  }

  &.profile {
    @apply bg-teal-200 text-teal-800;
  }
}
