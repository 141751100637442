@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}






















































.toggle.toggle--closed {
  @apply bg-red-500;

  &:hover {
    @apply bg-red-600;
  }

  span:first-of-type {
    @apply hidden;
  }

  span:last-of-type {
    @apply block;
  }
}
