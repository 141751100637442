@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}


















.tag {
  @apply leading-none text-xs font-bold text-white lowercase py-1 px-2 rounded inline-block whitespace-no-wrap;

  &.tag-xl {
    @apply text-xl py-2 px-3;
  }
}
