@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}







































































































ul {
  @apply flex items-center;

  li {
    @apply mr-2;

    > {
      button,
      span {
        @apply leading-none py-2 px-3;
      }
    }
  }

  button,
  .btn {
    @apply rounded bg-gray-200 cursor-pointer text-black;

    &.current {
      @apply font-semibold text-white bg-black pointer-events-none;
    }

    &:hover {
      @apply bg-gray-400;
    }
  }
}
