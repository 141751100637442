@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}








































































































a {
  @apply text-black;

  &:hover {
    @apply text-black;
  }

  svg {
    @apply mr-2;
  }
}
