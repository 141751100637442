@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}































mark {
  @apply bg-purple-100 rounded px-2 py-1 font-semibold text-purple-700;
}

mark + mark {
  @apply ml-2;
}

b {
  @apply mx-2;
}

mark > b {
  @apply mx-0;
}

button {
  @apply text-xl opacity-50 m-0 p-0 ml-6;

  &:hover {
    @apply opacity-100 text-red-700;
  }
}
