@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}


















































.description,
a.description:hover {
  @apply no-underline text-purple-900 rounded relative block font-mono bg-purple-100;
}

.description--regular {
  @apply p-4;
}

.description--compact {
  @apply text-sm p-2;
}

a.description:hover {
  @apply bg-purple-200;
}
