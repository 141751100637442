@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}


































span.fallback {
  @apply text-sm opacity-50 whitespace-no-wrap;
}
