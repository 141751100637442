@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}






















div {
  @apply font-semibold my-6;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
