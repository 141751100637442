@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}





















































ul {
  @apply flex items-center;

  li:not(:first-child) {
    @apply ml-3;
  }

  li {
    @apply whitespace-no-wrap;
  }
}
