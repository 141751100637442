.multiselect {
  @apply border rounded border-gray-400 p-2 pt-0 relative z-20 bg-white;
}

.multiselect__tags-wrap {
  @apply flex flex-row flex-wrap;
}

.multiselect__input {
  @apply border-none my-2;
}

.multiselect__content {
  @apply w-full;
}

.multiselect__placeholder {
  @apply mt-2 block;
}

.multiselect__content-wrapper {
  @apply overflow-y-auto mt-2;
}

.multiselect__element {
  @apply leading-none cursor-pointer;

  span span {
    @apply py-3 px-2 block whitespace-no-wrap;
  }

  &:nth-child(odd) {
    @apply bg-gray-100;
  }

  &:hover {
    @apply bg-purple-100 text-purple-700 font-semibold;
  }
}

.multiselect__placeholder {
  @apply text-gray-500;
}

.multiselect__tag,
.multiselect__single {
  @apply flex flex-row items-center p-2 bg-purple-100 text-purple-700 font-semibold mt-2 leading-none rounded;
}

.multiselect__single {
  @apply inline-block;
}

.multiselect__tag {
  @apply mr-2;
}

.multiselect__message {
  @apply p-2 block italic rounded text-gray-700 bg-gray-100;
}
