@mixin code {
  @apply font-mono text-indigo-900 bg-indigo-100 rounded py-1 px-1 leading-none whitespace-no-wrap;
}
@tailwind base;

@import "./mixins";

html {
  font-size: 12px;
}

h2 {
  @apply font-black text-3xl font-serif leading-tight;
}

h3 {
  @apply text-xl font-black font-serif leading-tight mt-8 text-2xl;
}

p {
  @apply mb-6 mt-2;
}

code {
  @include code;
}

input,
textarea,
button {
  @apply outline-none;
}

mark {
  @apply bg-yellow-200 px-1;
}

@tailwind components;

a,
.link {
  @apply cursor-pointer text-blue-600 underline;

  &:hover {
    @apply text-blue-800;
  }
}

.btn {
  @apply rounded-sm no-underline bg-gray-100 text-black leading-none whitespace-no-wrap py-2 px-3 block text-center shadow;

  &[disabled] {
    @apply cursor-not-allowed pointer-events-none opacity-50;
  }

  &:hover {
    @apply bg-gray-200;
  }

  &.btn-action {
    @apply text-white bg-blue-500 font-semibold;

    &:hover {
      @apply text-white bg-blue-600;
    }
  }

  &.btn-delete {
    @apply text-white bg-red-300 font-semibold;

    &:hover {
      @apply text-white bg-red-500;
    }
  }

  &.btn-lg {
    @apply rounded py-4 px-6 text-lg;
  }

  &.btn-secondary {
    @apply text-white bg-purple-500 font-semibold;

    &:hover {
      @apply bg-purple-700;
    }
  }
}

.at-a-glance {
  @apply p-4 rounded bg-blue-100 relative border border-blue-300 mb-8;

  table {
    @apply border-collapse;

    th {
      @apply text-left;
    }

    th,
    td {
      @apply px-2;
    }
  }

  h2 {
    @apply text-center pb-4;
  }
}

table.listing {
  @apply border-collapse w-full;

  th {
    @apply text-left border-b-2 border-gray-300 whitespace-no-wrap;
  }

  tbody {
    tr:nth-child(even) {
      @apply bg-gray-100;
    }

    tr:hover {
      @apply bg-yellow-100;
    }
  }

  td,
  th {
    @apply p-2 align-middle;
  }

  th {
    @apply py-1;
  }

  tbody + tbody tr:first-child td {
    @apply border-t-2 border-gray-200;
  }
}

p.error {
  @apply font-semibold text-red-700;
}

.field {
  @apply my-3;

  &.field-required > label::after {
    content: "*";
    @apply text-red-700 ml-1 font-black;
  }

  label {
    @apply font-black block text-xl;
  }

  input[type="text"],
  input[type="search"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="date"],
  textarea,
  select {
    @apply border bg-white rounded border-gray-400 p-2 block w-full leading-none;

    &:focus {
      @apply border-purple-500;
    }

    &[disabled],
    &[readonly] {
      @apply opacity-75 bg-gray-100 cursor-not-allowed;
    }
  }

  &.error {
    label {
      @apply text-red-700;
    }

    input[type="text"],
    input[type="search"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="date"],
    textarea {
      @apply bg-red-100 border-red-700;
    }
  }

  p.help,
  p.error {
    @apply p-0 my-1;

    a,
    .link {
      @apply font-semibold;
    }
  }

  p.help {
    @apply text-sm opacity-75;
  }

  p.error {
    @apply text-sm;
  }

  .radios {
    > div {
      @apply mt-2;
    }

    label {
      @apply w-full flex text-base flex-row items-center leading-none;

      input {
        @apply mr-2;
      }
    }

    small {
      @apply ml-6;
    }
  }
}

form .actions {
  @apply mt-8 flex flex-row items-center;
}

form.form-sm {
  label {
    @apply text-lg;
  }

  .actions {
    @apply mt-6;
  }
}

@import "./multiselect";

@tailwind utilities;

.container {
  max-width: 1024px;
}
